import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { API, Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import SyntaxHighlighter from 'react-syntax-highlighter';
import Markdown from 'react-markdown'
// import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import { Mic24Regular, Mic24Filled } from '@fluentui/react-icons';
import { useWhisper } from '@chengsokdara/use-whisper'
import { useAudioRecorder } from 'react-audio-voice-recorder';

import {
  FluentProvider,
  webLightTheme,
  makeStyles,
  useId,
  tokens,
  Button,
  Label,
  Drawer, DrawerHeader, DrawerHeaderTitle, OverlayDrawer, DrawerProps, DrawerBody,
  Toaster, useToastController, ToastTitle, Toast, ToastIntent,
  MessageBar,MessageBarActions, MessageBarTitle,MessageBarBody,
  Textarea, TextareaProps, Link, Dialog, DialogTrigger, DialogSurface, DialogBody, DialogTitle, DialogContent, DialogActions, Spinner, Select, SelectOnChangeData, Checkbox
} from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";

const useStyles = makeStyles({
  base: {
    display: "flex",
    flexDirection: "column",
    "& > label": {
      marginBottom: tokens.spacingVerticalMNudge,
    },
  },
});


interface AiMessage{
  me?: boolean;
  query_id?: string;
  message?: string;
  image?: string;
  framework?: string;
}


Amplify.configure({
  Auth: {
      region: awsExports.REGION,
      userPoolId: awsExports.USER_POOL_ID,
      userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID
  }
})

const API_BASE = "https://wa-np-tl-jamaica-qna-custom.azurewebsites.net"
//const API_BASE = "http://localhost:5000"
//const API_BASE = "https://705e-216-15-15-205.ngrok-free.app"


const API_KEY = "Z!37v!23BtB5%L7*E$uBM*L4#L194A";

function App() {
  const textareaId = useId("textarea");
  const toasterId = useId("toaster");
  const apiKeyId = useId("apiKeyId");
  const styles = useStyles();

  const [query, setQuery] = useState("");
  const [messages, setMessages] = useState<AiMessage[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [checkedDialect, setCheckedDialect] = useState<boolean|"mixed">(false);
  const [openAiKey, setOpenAiKey] = useState<string>();
  const [isOpenSettings, setIsOpenSettings] = useState(false);
  
  
  const {dispatchToast} = useToastController(toasterId)

  // const {
  //   recording,
  //   speaking,
  //   transcribing,
  //   transcript,
  //   pauseRecording,
  //   startRecording,
  //   stopRecording,
  // } = useWhisper({
  //   apiKey: OPENAI_KY, // YOUR_OPEN_AI_TOKEN
  // })

  const {
    startRecording,
    stopRecording,
    togglePauseResume,
    recordingBlob,
    isRecording,
    isPaused,
    recordingTime,
    mediaRecorder
  } = useAudioRecorder();

  // const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition();

  useEffect(() => {
    if(recordingBlob){
      trascribeApi();
    }  
  }, [recordingBlob]);


  const trascribeApi = async () =>{
    if(recordingBlob){
      setIsLoading(true);
      const formData = new FormData();
      const audioUrl = URL.createObjectURL(recordingBlob);
      formData.append("file", recordingBlob, 'file');
      formData.append("type", recordingBlob.type);
      const request = await fetch(`${API_BASE}/transcribe`, {
        method: "POST",
        headers: {
          "x-api-key": API_KEY,          
        },
        body: formData
      });
      if(request.status==200 || request.status ==201){
        const message = await request.json();
        if(message.success===true){
          setQuery(message.msg);
        }
      }
      setIsLoading(false);
    } 
  }

  const askApi = async () =>{
    if(query!==""){

      let newMessages = [
        ...messages,
        { me: true, message: query}
      ]

      setMessages(newMessages);
      setIsLoading(true);

      const formData = new FormData();
      formData.append("query", query);
      formData.append("dialect", checkedDialect===true?"true":"false");

      const request = await fetch(`${API_BASE}/query`, {
        method: "POST",
        headers: {
          "x-api-key": API_KEY,          
        },
        body: formData
      });
      if(request.status==200 || request.status ==201){
        const message = await request.json();
        setMessages([
          ...newMessages,
          { me: false, message: message.msg}
        ]);
        setQuery("");
      }
      setIsLoading(false);
    }
  }

  const notify = (message: string) =>{
    dispatchToast(
      <Toast>
        <ToastTitle>{message}</ToastTitle>
      </Toast>, 
      {intent: "success"}
    )
  }

  return (
    <FluentProvider theme={webLightTheme}>
      <div className='app'>
            <header>
              <div className='container'>
                <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems: 'center'}}>
                  <div>
                  <h1>Jamaica Passport</h1>
                  </div>
                  {/* <div>
                    <Button onClick={()=>setIsOpenSettings(true)}>Settings</Button>
                  </div> */}
                </div>
              </div>
            </header>
            <main>
              <section className='full'>
                <div className='container'>                  
                  {/* Message Area */}
                  <div>
                    {messages.map((message, index)=>{
                      let bubbleStyle = message.me?"MessageBubble MessageBubbleMe":"MessageBubble";
                      return(
                        <div className={message.me?`MessageMe`:`MessageSystem`} key={index} >
                          <div className={bubbleStyle}>
                            <Markdown>
                              {message.message}
                            </Markdown>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
                <OverlayDrawer
                  position='end'
                  open={isOpenSettings}
                  onOpenChange={(_, {open})=> setIsOpenSettings(open)}
                >
                  <DrawerHeader>
                    <DrawerHeaderTitle
                      action={
                        <Button
                          appearance='subtle'
                          aria-label='close'
                          icon={<Dismiss24Regular/>}
                          onClick={()=>setIsOpenSettings(false)} 
                        />
                      }
                    ></DrawerHeaderTitle>
                  </DrawerHeader>
                  <DrawerBody>
                    <p>Settings</p>
                    <div>
                      <Label htmlFor={apiKeyId}>ApiKey</Label>
                      <br/>              
                      <Textarea 
                        id={apiKeyId} 
                        value={openAiKey} 
                        onChange={(event, data)=>setOpenAiKey(data.value)}
                        style={{width:'100%'}}
                        resize='vertical'
                      /> 
                    </div>
                    <br/>
                  </DrawerBody>
                </OverlayDrawer>           
              </section>
            </main>
            <footer>
              <div className='container'>
                <div>
                  {/* Loader */}
                  <div hidden={!isLoading}>
                    <Spinner label={"processing..."} size="small"/>
                  </div>
                  <br/>
                </div>
                {isRecording?
                  <Mic24Filled onClick={()=>stopRecording()}/>:
                  <Mic24Regular onClick={()=>{
                    startRecording();
                  }}/>
                }
                <Textarea 
                  id={textareaId} 
                  value={query} 
                  onChange={(event, data)=>setQuery(data.value)}
                  style={{width:'400px', marginRight: '1em'}}
                />              
                <Button appearance='primary' onClick={askApi}>Ask</Button>
                <Checkbox
                  checked={checkedDialect}
                  onChange={(event, data)=> setCheckedDialect(data.checked)}
                  label={"patois"}
                />
              </div>
            </footer>
          </div>
    </FluentProvider>
  );
}

export default App;